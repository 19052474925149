<template>
        {{ transformDate(dateIn)}}
</template>

<script setup>
import { defineProps } from "vue"

defineProps({
    dateIn: String,
});

function transformDate(newDate) {
    const date = new Date(newDate)
    return (date.getDate()) + ' ' + getMonthLabel(date.getUTCMonth()) + ' ' + date.getFullYear()
}

function getMonthLabel(month) {
    switch (month+1) {
        case 1: return 'Janvier'
        case 2: return 'Février'
        case 3: return 'Mars'
        case 4: return 'Avril'
        case 5: return 'Mai'
        case 6: return 'Juin'
        case 7: return 'Juillet'
        case 8: return 'Août'
        case 9: return 'Septembre'
        case 10: return 'Octobre'
        case 11: return 'Novembre'
        case 12: return 'Décembre'
        default:
    }
}
</script>

<style></style>