<template>
    <form @submit.prevent="onSubmit" id="form" ref="form">
        <div>
            <input class="form" type="text" id="name" name="name" placeholder="Nom" required />
        </div>
        <br />
        <div>
            <input class="form" type="email" id="mail" name="mail" placeholder="E-mail" />
        </div>
        <br />
        <div>
            <input class="form" type="telephone" id="tel" name="tel" placeholder="Téléphone" />
        </div>
        <br />
        <div>
            <textarea class="form" type="email" id="msg" name="msg" placeholder="Message" rows="5" cols="21"></textarea>
        </div>
        <br />
        <div>
            <button type="submit" id="valid">Envoyer</button>
        </div>

    </form>
    <Popup v-if="popupTrigger.successTrigger" :togglePopup="() => togglePopup('successTrigger')">
        <p>Votre message a bien été envoyé !</p>
    </Popup>
    <Popup v-if="popupTrigger.errorTrigger" :togglePopup="() => togglePopup('errorTrigger')">
        <h4>Une erreur technique est survenue !</h4>
        Merci de nous contacter par téléphone au <a :href="'tel:' + phone" style="color:=var(--link-text-color)">{{ displayPhone }}</a>
        ou par mail à l'adresse <a :href="'mailto:' + mail" style="color:=var(--link-text-color)">{{ mail }}</a>
    </Popup>

</template>

<script setup>
import emailjs from 'emailjs-com'
import { onMounted, ref, defineProps } from 'vue';
import Popup from './CustomPopup.vue'

defineProps({
    mail: String,
    phone: String,
    displayPhone: String
});

const popupTrigger = ref({
    successTrigger: false,
    errorTrigger: false
});

function togglePopup(trigger) {
    popupTrigger.value[trigger] = !popupTrigger.value[trigger];
}

onMounted(() => {
    emailjs.init('g6QmXq7nM310TDaWj');
})

function onSubmit(event) {
    const form = document.getElementById('form');

    emailjs.sendForm('service_y0tnirf', 'template_0fiot37', form)
        .then(function () {
            togglePopup('successTrigger');
            event.target.reset();
        }, function (error) {
            console.log('FAILED...', error);
            togglePopup('errorTrigger');
        });
}
</script>

<style>
.form {
    width: 90%;
    background-color: var(--background-color);
    border-color: var(--link-text-color);
    border-width: 1px;
    color: var(--link-text-color);
    border-radius: 5px 5px;
    border-style: solid;
}

button {
    background-color: var(--menu-background-color);
    ;
    color: var(--link-text-color);
    border: 0ch;
    width: 60px;
    height: 30px;
    border-radius: 5px 5px;
}

textarea {
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}
</style>