<template>
    <br />
    <h3>Les Constellations Familiales</h3>
    <p>Bert Hellinger a développé les constellations familiales dans les années 90.</p>
    <p>Cet outils thérapeutique considère qu’une partie de nos tourments proviennent des situations ou des événements vécus
        par
        nos ancêtres.
        En effet, selon Bert Hellinger, les actes de nos aïeux entraînent des conséquences sur nos vies actuelles. </p>
    <p>Cette thérapie brève permet d’explorer les liens et les dynamiques dans les systèmes familiaux. Les
        constellations familiales peuvent aider les personnes à comprendre leurs racines émotionnelles.
        Elles nous permettent de rétablir l’équilibre au sein de notre famille, tout en acceptant notre Histoire.
        Elles nous rappellent aussi l’importance de faire le deuil pour lâcher prise et continuer notre propre vie.</p>

    <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="100px" width="100x" />
    <p>Les constellations familiales peuvent être collectives ou individuelles. </p>

    <p>Individuelles, elles utilisent des représentations visuelles, telles que des figurines ou des cartes, pour
        représenter les membres de la famille.
        Grâce à leur emplacement, les constellés peuvent alors découvrir et explorer les modèles de comportements
        répétitifs et les problèmes de relations
        qui peuvent être transmis de génération en génération. </p>
    <p>En groupe, le constellé choisira des personnes présentes pour représenter ses ancêtres. Ces personnes, dites
        représentants, l’aideront à empathiser avec les membres de sa famille.
        Suivant leur ressenti, leur place dans l’espace, ils nous permettront de comprendre les blocages familiaux.
        Les représentants, par un jeu de miroir, travaillent aussi sur leur propre existence. </p>
    <p>Cette approche est souvent utilisée en complément d'autres thérapies pour traiter des problèmes tels que la
        dépression, l'anxiété, les relations difficiles etc… </p>
    <p>A Casa Bien-Être je propose régulièrement des ateliers de groupe. Pour connaitre les dates, je vous
        invite à suivre mon Facebook ou m’écrire. Pour les constellations individuelles, vous pouvez prendre
        rendez-vous sur Médoucine ou à m’envoyer un mail.</p>
    <br />
</template>


<script setup>

</script>


<style></style>