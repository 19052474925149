<template>
  <section v-if="trigger.error">
    <ErrorMessage />
  </section>

  <section v-else-if="trigger.loading">
    <LoadCircle />
  </section>

  <section v-else>
    <h1>Mes articles</h1>
    <div class="articles" v-for="post in posts" :key="post.id">
      <div class="article" @click="readPost(post.id)">
        <div><b>{{ post.title }}</b></div>
        Publié le <DisplayDate :dateIn="post.published" />
      </div>
    </div>
  </section>
</template>


<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import blogService from '@/services/BlogService';
import LoadCircle from '@/components/LoadCircle.vue';
import DisplayDate from '@/components/DisplayDate.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

const router = useRouter();

const trigger = ref({
  loading: true,
  error: false
});

const posts = ref([]);

function readPost(id) {
  router.push({ name: 'post', params: { id: id } })
}

onMounted(() => {
  blogService().getPosts()
    .then(response => {
      posts.value = response
      trigger.value['loading'] = false
    })
    .catch(error => {
      console.error(error)
      trigger.value['error'] = true
    })
})


</script>


<style>
.articles {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style-type: none;

}

.article {
  width: 100%;
  padding: 10px;
  margin: 5px;
  display: block;
  border-radius: 4px;
  background-color: var(--menu-background-color);
}
</style>