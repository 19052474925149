<template>
    <br />
    <h3>Elisabeth Terrière</h3>
    <p>J’ai étudié à CAPERIA (aujourd’hui AFRATAPEM), où j’ai reçu le diplôme d’Aide Culturelle à la
        Personne. Dans la continuité de ce cursus, j’ai étudié à la Faculté de médecine de Tours, ou j’ai
        obtenu le diplôme d’Art-thérapeute.</p>
    <p>Il me semble important de préciser mon cursus scolaire, car l’obtention d’un diplôme universitaire
        d’Art-thérapie (UFR Médecine) valorise la caution scientifique du métier d’Art-thérapeute.</p>
    <p>Mes dominantes artistiques sont les arts plastiques, l’expression scénique et l’écriture.</p>
    <p>Mon parcours de vie m’a amené à travailler non seulement en France mais aussi en République
        Démocratique du Congo et au Chili. Exercer le métier d’Art-thérapeute sur trois continents m’a
        permis d’acquérir une plus ample vision du monde et d’appréhender différemment ma façon
        d’interagir avec l’autre. A travers mes voyages, mes qualités de thérapeute ont grandi avec moi.</p>
    <br />
    <img src="../assets/therapeute/therapeute.svg" alt="therapeute_art" height="56px" width="50x" />
    <img src="../assets/therapeute/art.gif" alt="art_therapeute" />
    <img src="../assets/therapeute/therapeute.gif" alt="therapeute" />
    <img src="../assets/therapeute/art.png" alt="art" />
    <img src="../assets/therapeute/therapeute.png" alt="art_therapie" />
    <br />
    <br />
    <img src="../assets/therapeute/therapeute.jpg" alt="therapie_art" />
</template>

<script setup></script>

<style></style>