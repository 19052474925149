<template>
    <br />

    <p> Casa Bien-Être est un espace thérapeutique, dédié à 100% à la famille et à l’enfance.
        Je vous propose</p>

    <h4>Des ateliers d’Art-Thérapie pour les enfants</h4>
    <p>Mon quotidien est animé par des enfants hypersensibles ou qui viennent de subir des
        traumatismes comme une séparation, un deuil, un déménagement ou un accident… Cela se traduit
        par des colères ou, au contraire, des petits qui se renferment sur eux-mêmes. L’Art-thérapie donne
        aux enfants les clefs pour gérer leurs émotions. Au fur et à mesure des séances, vous les redécouvrez
        apaisés, plein d’une nouvelle maturité.</p>
    <p>J’accueille aussi des enfants à haut potentiel ou ayant des troubles du langage et des
        apprentissages. Grâce à l’Art, ils apprennent à ne pas se sentir en dehors des normes et à gérer leur
        particularité pour en faire une force.</p>

    <h4>Des cercles de parents</h4>
    <p>En tant que parents, cela fait du bien de pouvoir échanger en toute liberté autour des
        problématiques auxquelles nous sommes confrontés à la maison. A Casa Bien-Être vous trouvez un lieu
        bienveillant où vous confier et recevoir des conseils qui conviendront à votre mode de vie.</p>


    <h4>Des cercles de futurs et jeunes parents</h4>
    <p>Vous avez envie de vivre votre grossesse le plus sereinement possible, ou vous envisagez un
        accouchement physiologique? Vous apprenez tout doucement à devenir parents et avez envie
        d’échanger avec des personnes qui vivent la même chose que vous? Je vous propose un lieu
        d’échange ou j’invite régulièrement d’autres thérapeutes.</p>

    <h4>Des constellations familiales</h4>
    <p>En groupe ou individuelles, elles vous permettent de décider de celui/celle que vous voulez
        être. Elles ramènent la paix et l’équilibre dans votre vie.</p>

    <p>Pour suivre mon actualité je vous invite à aller sur mon <a href="https://casa-bienetre.blogspot.com/"
            style="color:=var(--link-text-color)">blog</a></p>
    <br />
    <br />

    <img src="../assets/therapeute/therapeute.svg" alt="therapeute_art" height="56px" width="50x" />
    <img src="../assets/therapeute/art.gif" alt="art_therapeute" />
    <img src="../assets/therapeute/therapeute.gif" alt="therapeute" />
    <img src="../assets/therapeute/art.png" alt="art" />
    <img src="../assets/therapeute/therapeute.png" alt="art_therapie" />
    <br />
    <br />
    <br />
    <img src="../assets/therapie/therapie.jpg" alt="art_therapie" width="50%" height="30%" />
</template>

<script setup>
</script>

<style></style>