<template>
    <br />

    <h3>Art-thérapie</h3>

    <div class="price">
        <br />

        <div class="priceLeft">
            <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="50px" width="50x" />
            <h4>Appel téléphonique pour prendre contact et poser nos objectifs.</h4>
            <p>
                Offert<br />
                15 minutes
            </p>
        </div>

        <div class="priceCenter">
            <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="50px" width="50x" />
            <h4>Séance individuelle<br />  <i>Enfants / Adolescents </i></h4>
            <p>
                65€ <br />
                45 min à 1h
            </p>
        </div>

        <div class="priceRight">
            <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="50px" width="50x" />
            <h4>Séance collective<br /><i>Fratrie</i></h4>
            <p>
                85€<br />
                Entre 45 min à 1h30
            </p>
        </div>
        <br />
    </div>



    <div class="price">
        <br />
        <div class="priceLeft">
            <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="50px" width="50x" />
            <h4>Rencontre et élaboration de la prise en charge de votre enfant</h4>
            <p>
                45€ <br />
                30 min
            </p>
        </div>

        <div class="priceCenter">
            <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="50px" width="50x" />
            <h4>Lot de 5 séances individuelles<br />  <i>Enfants / Adolescents </i></h4>
            <p>250€</p>
        </div>

        <div class="priceRight">
            <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="50px" width="50x" />
            <h4>Lot de 5 séances collectives<br /><i>Fratrie</i></h4>
            <p>350€</p>
        </div>

        <br />
    </div>

        <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="50px" width="50x" />
        <h4>Pack gestion des émotions</h4>
        <p>500€</p>
        <p>
            Spécialisée dans la gestion des émotions, je propose à vos enfants des clefs pour trouver d’eux-
            mêmes l’apaisement, une fois qu’ils auront fait les séances d’Art-Thérapie à Casa Bien-Être.<br /> Ce pack
            vous accompagne aussi, entant que parent, pour retrouver votre place dans la famille et vous sentir
            mieux.
            <br />Le pack comprend:
            <br />
            <br />Un appel téléphonique de 15 minutes pour prendre contact et poser nos objectifs.
            <br />Rencontre et élaboration de la prise en charge de votre enfant
            <br />5 séances individuelles avec votre enfant
            <br />2 séances de suivi avec vous
            <br />2 appels si vous ressentez le besoin de vous décharger
            <br />Une bouteille sensorielle fabriquée avec votre enfant
            <br />Un Kit pour faire des Arts-Plastiques à la maison
        </p>

    <!-- <div class="isolation">
        <img src="../assets/therapie/therapeute.svg" alt="therapie_art" height="56px" width="50x" />
        <img src="../assets/therapie/art.gif" alt="art_therapie" />
        <img src="../assets/therapie/therapeute.gif" alt="therapeute" />
        <img src="../assets/therapie/art.png" alt="art" />
        <img src="../assets/therapie/therapeute.png" alt="art_therapeute" />
    </div> -->
</template>

<script setup></script>

<style>
.price {
    width: 100%;
    display: inline-flex;
}

.priceLeft {
    float: left;
    width: 33%;
    padding-left: 5%;
}

.priceCenter {
    float: center;
    width: 33%;
}

.priceRight {
    float: right;
    width: 33%;
    padding-right: 5%;
}
</style>