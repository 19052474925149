import { createRouter, createWebHashHistory } from "vue-router";

import Home from '@/views/HomePage.vue';
import Elisabeth from '@/views/ElisabethPage.vue';
import ArtTherapie from '@/views/ArtTherapiePage.vue';
import Constellations from '@/views/ConstellationsPage.vue';
// import Cocon from '@/views/CoconPage.vue';
// import YinYoga from '@/views/YinYogaPage.vue';
import Tarifs from '@/views/TarifsPage.vue';
import Blog from '@/views/BlogPage.vue';
import Post from '@/views/PostPage.vue';
import Contact from '@/views/ContactPage.vue';
import NotFound from '@/views/NotFound.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        alias: "/home"
    },
    {
        path: '/therapeute',
        name: 'therapeute',
        component: Elisabeth
    },
    {
        path: '/art-therapie',
        name: 'art-therapie',
        component: ArtTherapie
    },
    {
        path: '/constellations',
        name: 'constellations',
        component: Constellations
    },
    // {
    //     path: '/cocon',
    //     name: 'cocon',
    //     component: Cocon
    // },
    // {
    //     path: '/yinyoga',
    //     name: 'yinyoga',
    //     component: YinYoga
    // },
    {
        path: '/tarifs',
        name: 'tarifs',
        component: Tarifs
    },
    {
        path: '/blog',
        name: 'blog',
        component: Blog
    },
    {
        path: '/post/:id',
        name: 'post',
        component: Post,
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
  });

  export default router;