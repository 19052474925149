<template>
    <br />
    <h3>Qu'est-ce que l'art-thérapie ?</h3>
    <p> S’il fallait définir mon travail simplement, je vous dirais que l’Art-thérapie, c’est appuyer sur ce qui
        fonctionne bien chez vous, pour faire en sorte que vous alliez mieux. Evidemment, grâce à l’Art. </p>
    <img src="../assets/therapie/therapie.svg" alt="art_therapie" height="100px" width="100x" />
    <br />
    <h3>A qui s’adresse l’Art-thérapie: </h3>
        <p> Casa Bien-Être est dédié à la famille et à l'enfance.</p>
        <p> - Je propose donc des séances aux enfants qui ont des troubles du langage et des apprentissages (Aussi des
            troubles de l’attention, qui font partie de cet ensemble.).
            <br /> Ce que l’on nomme aujourd’hui communément les troubles en DYS : dyslexie, dyspraxie, dysphasie, ainsi
            que
            certaines manifestations induites de ces troubles comme la dyscalculie, la dysgraphie ou la dysorthographie.
        </p>
        <p> - L’Art-thérapie s’adresse aux enfants manifestant des signes de dépression ou d’anxiété depuis la
            séparation de
            leur parent ou après un deuil. </p>
        <p> - J’organise des séances pour les enfants hyperémotifs.</p>
        <p> - Je propose aussi des ateliers pour consolider ou renouer les liens entre parents et enfants.</p>
        <p> - Evidemment j’accueille les parents qui veulent apprendre à gérer leur parentalité après une séparation.</p>
        <p> - Les parents rencontrant des difficultés à gérer leur émotivité (gestion de la colère, de la peine...)
            sont les
            bienvenus aux séances d’Art-thérapie. L’Art peut vous aider à débloquer les mots qui vous conduiront vers
            une
            plus grande connaissance de vous-même et la gestion de vos émotions. </p>
    <br />
    <img src="../assets/therapie/Art.svg" alt="art_therapie" height="100px" width="100x" />
    <br />
    <br />
    <i>«C'est l'exploitation du potentiel artistique dans une visée humanitaire et thérapeutique.» Richard Forestier.
    </i>
</template>

<script setup></script>

<style>
</style>