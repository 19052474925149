<template>
    <h1>
        Vous vous êtes perdus ? Cette page n'existe pas
    </h1>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();


onMounted(() => {
    router.push({ name: 'home' })
})

</script>

<style></style>