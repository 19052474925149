<template>
  <h1>Casa Bien-Être</h1>
  <NavBar />
  <router-view :mail="mail" :displayPhone="displayTel" :phone="tel" />
  <div>
    <p class="bottomPage">
      <a :href="'mailto:' + mail" style="color:=var(--link-text-color)">{{ mail }}</a>
      |
      <a :href="'tel:' + tel" style="color:=var(--link-text-color)">{{ displayTel }}</a>

      <a class="imgBottomPage"
        href="https://www.medoucine.com/consultation/nantes/elisabeth-terriere/4277?utm_source=bouton-rdv&utm_medium=pack-com&utm_campaign=elisabeth-terriere"><img
          height="47" src="https://cdn.medoucine.com/pack-communication/bouton3.png?v=1" alt="Prendre RDV" /></a>

    </p>

  </div>

</template>

<script setup>
import NavBar from './components/NavBar.vue'

const mail = "elisabeth@casa-bienetre.fr"
const tel = "+33661977561"
const displayTel = "06 61 97 75 61"

</script>

<style>
.bottomPage {
  background-color: var(--menu-background-color);
  margin-top: 60px;
  padding: 5px;
}

.imgBottomPage {

  margin-left: 1%;
  vertical-align: middle;
}

.body {
  background-color: var(--background-color);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
