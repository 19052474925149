<template>
    <section v-if="trigger.error">
        <ErrorMessage />
    </section>

    <section v-else-if="trigger.loading">
        <LoadCircle />
    </section>

    <section v-else>
        <h3>{{ post.title }}</h3>
        <DisplayDate :dateIn="post.published" />
        <p v-html="post.content"></p>
    </section>
</template>
    
<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import blogService from '../services/BlogService.js';
import LoadCircle from '@/components/LoadCircle.vue';
import DisplayDate from '@/components/DisplayDate.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

const route = useRoute();

const trigger = ref({
    loading: true,
    error: false
});

const post = ref();

onMounted(() => {
    blogService().getPostById(route.params.id)
        .then(response => {
            post.value = response
            trigger.value['loading'] = false
        })
        .catch(error => {
            console.error(error)
            trigger.value['error'] = true
        })
})

</script>
    
<style></style>