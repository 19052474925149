import axios from 'axios';

  const blogId = '7498600872645366139';
  const apiKey = 'AIzaSyBkYUGie71xYsR6uTgaT5R1bwORBbwmyMM'

  const service = axios.create({
    baseURL: 'https://www.googleapis.com/blogger/v3/blogs/' + blogId
  });

  function getPostById(postId) {
    return service.get('/posts/' + postId + '?key=' + apiKey)
      .then(response => {
        // console.log(response);
        return response.data
       })
      .catch(error => console.log(error))
  }

  function getPosts() {
    return service.get('/posts?maxResults=100&fetchBodies=false&key=' + apiKey)
      .then(response => {
        // console.log(response);
        return response.data.items
      })
      .catch(error => {
        console.error(error);
      });
  }

export default function blogService() {
  return {
    getPosts,
    getPostById
  }
}