<template>
    <ul class="menu">
        <div class="menu-indicator" :style="{ left: positionToMove, width: sliderWidth }"></div>
        <li class="menu-item" v-for="link in links" :key="link.id" 
            :ref="el => refs.push(el)">
            <router-link :to="{name: link.name}" class="menu-link" :class="link.id === selectedIndex ? 'active' : null">
                {{ link.text }}
            </router-link>
        </li>
    </ul>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router';

const route = useRoute();

const sliderPosition = ref(0)
const selectedElementWidth = ref(0)
const selectedIndex = ref(0)

const refs = ref([])

const links = [{
    id: 0,
    name: "home",
    text: "Accueil"
},
{
    id: 1,
    name: "therapeute",
    text: "Qui suis-je"
},
{
    id: 2,
    name: "art-therapie",
    text: "Art-thérapie"
},
{
    id: 3,
    name: "constellations",
    text: "Constellations Familiales"
},
// {
//     id: 4,
//     name: "cocon",
//     text: "Cocon"
// },
// {
//     id: 5,
//     name: "yinyoga",
//     text: "Yoga"
// },
{
    id: 4,
    name: "tarifs",
    text: "Mes Offres"
},
{
    id:5,
    name: "blog",
    nameAlias: "post",
    text: "Blog"
},
{
    id: 6,
    name: "contact",
    text: "Me Contacter"
}]

function sliderIndicator(id) {
    let el = refs.value[id]

    sliderPosition.value = el.offsetLeft
    selectedElementWidth.value = el.offsetWidth
    selectedIndex.value = id
}

const positionToMove = computed(() => sliderPosition.value + "px")
const sliderWidth = computed(() => selectedElementWidth.value + "px")


watch(route, (newValue) => {
    let id = links.find(l => l.name === newValue.name || l.nameAlias === newValue.name).id
    sliderIndicator(id)
});

</script>

<style>

/*ul*/
.menu {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    background-color: var(--menu-background-color);
    border-radius: 4px;
    list-style-type: none;
    overflow: hidden;
}

.menu-link:hover,
.menu-link.active {
    color: var(--active-color);
    /*background-color: var(--active-background-color);*/
}

/*li*/
.menu-item {
    padding: 5;
    margin: 5px;
    display: inline-flex;
    border-radius: 4px;
}

/*a*/
.menu-link {
    padding: 0.75rem 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--link-text-color);
    text-decoration: none;
}

/*slider*/
.menu-indicator {
    position: absolute;
    height: 0.25rem;
    background-color: var(--active-color);
    bottom: 0;
    left: 0;
    margin: auto;
    width: 3rem;
    transition: all ease 0.5s;
}
</style>